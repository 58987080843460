<template>

  <v-row class="lbm_adaptive-container">
    <v-col cols="12" class="ma-0 pa-0">
      <v-row>
        <v-col col="12" lg="5" offset-lg="7" class="pr-12">
          <v-text-field v-model="searchImage"
                        :placeholder="$t('forms.section.edit.image.search.placeholder')"
                        :label="$t('forms.section.edit.image.search.label')"
                        prepend-inner-icon="mdi-magnify"></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="lbm_adaptive-container__item" cols="6" lg="3" v-for="(image, key) in _images" :key="key">
      <div style="position:relative;">
        <v-img @error="removeFromItems(image)"
               @click="chooseImage(image, key)"
               :class="{'d-flex justify-center align-center lbm_section-img content': true, 'chosen': chosen === key}"
               :src="image.src"
               :lazy-src="image.src">
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-btn @click="editImage(image.src, image.id)" style="position: absolute; right: 5%; top: 5%" fab x-small dark color="primary">
          <v-icon x-small>mdi-image-edit</v-icon>
        </v-btn>
        <v-btn @click="editImageProperties(image)" style="position: absolute; right: 22%; top: 5%" fab x-small dark color="primary">
          <v-icon x-small>mdi-pencil</v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col cols="12">
      <v-card flat tile min-height="1rem" :loading="pending" v-intersect="infiniteScrolling"></v-card>
    </v-col>
    <v-dialog :max-width="$vuetify.breakpoint.width / 1.7" v-model="props">
      <v-card>
        <v-card-title class="elevation-2">
          <v-spacer></v-spacer>
          <v-btn @click="props = false" small fab color="error" dark>
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="ma-0 d-flex justify-center">
            <template v-for="(value, key) in image">
              <template v-if="$options.filters.inArray(key, hiddenKeys)"></template>
              <template v-else>
                <template v-if="key !== 'src'">
                  <v-col :key="key" cols="6" class="d-flex flex-column justify-start">
                    <label class="my-2">{{ $t(`image.${key}.label`) }}</label>
                    <v-text-field dense :placeholder="$t(`image.${key}.placeholder`)"
                                  solo v-model="image[key]"></v-text-field>
                  </v-col>
                </template>
              </template>
            </template>
          </v-row>
        </v-card-text>
        <v-card-actions class="pr-8 pb-8">
          <v-spacer/>
          <v-btn color="primary" dark @click="saveImageProperties">{{ $t('buttons.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'Images',
  data () {
    return {
      image: null,
      searchImage: null,
      images: [],
      type: 'update',
      page: 1,
      pending: false,
      chosen: null,
      props: false,
      hiddenKeys: [
        'id',
        'imageable_id',
        'imageable_type',
        'created_at',
        'updated_at'
      ]
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    $route (to, from) {
      this.fetchData()
    }
  },
  methods: {
    removeFromItems (item) {
      const index = this.images.indexOf(item)
      this.images.splice(index, 1)
    },
    async fetchData () {
      await this.$http.get(this.url).then(response => {
        this.images = response.data.images.data
      })
    },
    infiniteScrolling (entries, observer, isIntersecting) {
      this.pending = true
      setTimeout(() => {
        this.page++
        this.$http
          .get(this.url)
          .then(response => {
            response.data.images.data.forEach(item => this.images.push(item))
            this.pending = false
          })
          .catch(err => {
            console.log(err)
          })
      }, 500)
    },
    editImage (src, id) {
      this.$http.post('api/v2/get-image-data-url',
        {
          src: src
        }
      )
        .then(response => {
          window.sessionStorage.setItem('image', response.data.success)
          window.sessionStorage.setItem('image_id', id)
          this.$router.push({ name: 'admin.image-editor', params: { src: response.data.success, id: id } })
        })
    },
    editImageProperties (image) {
      this.props = true
      this.image = image
    },
    chooseImage (image, key) {
      this.chosen = key
      this.$store.dispatch('setImg', image).then(() => {
        this.$store.dispatch('setImageDialog', false)
      })
    },
    saveImageProperties () {
    }
  },
  computed: {
    url () {
      return `api/v2/auth/images?page=${this.page}`
    },
    _images () {
      if (this.searchImage) {
        return this.images.filter((item) => {
          return item.src.includes(this.searchImage)
        })
      } else {
        return this.images
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .chosen {
    border: 3px solid #40A226;
  }
</style>
